<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { getAllTribes } from "@/services/api/tribe";
import { deleteTribes } from "@/services/api/tribe";
import { userMethods } from "@/state/helpers";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Nomral users",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout },
  data() {
    return {
      tableData: [],
      title: "",
      items: [
        {
          text: "Users",
          href: "/",
        },
        {
          text: "Normal",
          active: true,
        },
      ],
      rows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],

      fields: [
        { key: "name", label: "Name & Title" },
        { key: "rating" },
        { key: "no_of_days" },
        { key: "Details", tdClass: "tribe-details-column" },
        { key: "options" },
      ],
      dataLoading: false,
    };
  },
  computed: {},
  mounted() {},
  created() {
    this.getNormalUsers();
  },

  methods: {
    /**
     * Search the table data with search input
     */
    ...userMethods,
    getNormalUsers() {
      this.dataLoading = true;
      this.tableData = [];
      getAllTribes({ page: this.currentPage, perPage: this.perPage })
        .then((res) => {
          console.log(res.data);
          this.tableData = res.data;
          console.log(this.tableData);
          this.rows = res.data.tribes[0].count;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dataLoading = false;
          console.log | "Loaded";
        });
    },

    selectedUser(item) {
      this.selectNormalUser(item);
      this.$router.push({ name: "Normal Users details" });
    },
    pageChange(val) {
      this.currentPage = val;
      this.getNormalUsers();
    },
    perPageChange() {
      this.getNormalUsers();
    },
    tribesList() {
      this.$router.go(this.$router.currentRoute);
    },

    editTribe(tribeItems) {
      this.$router.push({
        name: "editTribe",
        params: { tribeItems: tribeItems },
      });
    },
    goToAddTribe() {
      this.$router.push("/tribes/add");
    },

    deleteTribe(id) {
      if (
        this.$bvModal
          .msgBoxConfirm("Do you really want to delete?")
          .then((e) => {
            if (e == true) {
              deleteTribes({ tribeId: id });
              this.tribesList();
            }
          })
      );
    },
  },
};
</script>
<template>
  <Layout>
    <div class="row mt-1">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @change="perPageChange"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>

      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Search:
            <b-form-input
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <a
              href="javascript:void(0);"
              style="padding: 5px 17px"
              class="btn btn-success mb-2"
              @click="goToAddTribe()"
            >
              <i class="mdi mdi-plus mr-2"></i>Add tribe
            </a>

            <!-- Table -->
            <div class="table-responsive mb-0" style="min-height: 70vh">
              <b-table
                :items="tableData"
                :fields="fields"
                :per-page="perPage"
                :busy="dataLoading"
                @row-clicked="selectedUser"
                :hover="true"
                class="tribes-table"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="pl-4">Loading...</strong>
                  </div>
                </template>

                <template #cell(name)="data">
                  <div class="d-flex" style="width: 260px">
                    <img
                      :src="data.item.cover_image"
                      width="80"
                      alt=""
                      srcset=""
                      class="rounded"
                    />
                    <div class="pl-4">
                      <div>
                        <strong> {{ data.item.name }}</strong>
                      </div>
                      <div class="mt-1">
                        {{ data.item.title }}
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(Details)="data">
                  <div class="row">
                    <div class="col-5">Region</div>
                    <div class="col-5">
                      {{ data.item.region }}
                    </div>
                    <div class="col-5">Climate</div>
                    <div class="col-7">
                      {{ data.item.climate }}
                    </div>
                    <div class="col-5">Challenge</div>
                    <div class="col-7">
                      {{ data.item.challenge }}
                    </div>
                  </div>
                </template>
                <template #cell(options)="data">
                  <a
                    href="javascript:void(0);"
                    class="mr-3 text-primary"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="editTribe(data)"
                  >
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    class="text-danger"
                    v-b-tooltip.hover
                    title="Delete"
                    @click="deleteTribe(data.item._id)"
                  >
                    <i class="mdi mdi-trash-can font-size-18"></i>
                  </a>
                  <!-- {{ data }} -->
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="float-right">
          <ul class="pagination pagination-rounded mb-4">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @change="pageChange"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.row:hover {
  cursor: pointer;
}
.tribes-table .col-4 {
  font-weight: 900;
}
.tribe-details-column .row {
  width: 298px;
}
</style>